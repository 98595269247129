<template>
  <div id="logoutMessageModal" class="modal">
    <div class="modal-content">
      <h4 class="modal-title">Session Timeout</h4>
      <div class="modal-body font-14">
        <p>Your session is about to expire because of inactivity.<br> Press any key/move your mouse to continue this session.</p>
      </div>
    </div>
  </div>
</template>

<script>
import "materialize-css/dist/js/materialize.min";
import M from "materialize-css";

export default {
  name: "LogoutMessageModalComponent",
  /**
   * @description Component local variables
   * @return {Object} data
   * @return {undefined|FormSelect} data.instance
   */
  data() {
    return {
      instance: undefined
    };
  },
  props:{
      showMessage:{
      type:Boolean,
          default:false
  }},

  methods: {
    /**
     * @description Initialize a new Materialize select component
     * @param {Object} options
     * @return {FormSelect}
     * @see https://materializecss.com/select.html#options
     */
    initializeModal(options = {dismissible:true}) {
      return M.Modal.init(this.$el, options);
    },
    closeModal() {
      this.instance.close();
    }
  },
  mounted() {
    window.console.log("component mounted");
    this.instance = this.initializeModal();

  },
  watch: {
      showMessage() {

      //this.instance.destroy();

      //this.$nextTick(() => this.instance = this.initializeModal());

      if (this.showMessage) {
        window.console.log("Show Message");
        this.instance.open();
      }
      else {
          if(typeof this.instance !== "undefined" && this.instance !== null && this.instance.isOpen)
          {
              this.instance.close();

          }
      }
    }
  },

  destroyed() {
      this.instance.close();
    //this.$el.removeEventListener('change', this.changeHandler)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
