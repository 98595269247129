<template>
    <logout-message-modal-component :showMessage="showWarningMessage"></logout-message-modal-component>
</template>
<script>
    import LogoutMessageModalComponent from "../components/materialize/LogoutMessageModalComponent.vue"


    export default {
        name: 'LogoutComponent',
        components:{
            LogoutMessageModalComponent
        },
        data(){
            return{
                logoutTimer:null,
                messageTimer:null,
                showWarningMessage:false,
                events:["click","mousemove","mousedown","scroll","keypress"],
            }
        },
        created: function()
        {

        },
        mounted: function()
        {

            let _thisObj = this;
            _thisObj.events.forEach(function (event) {
                window.addEventListener(event,_thisObj.resetTimers);

            });

                this.setTimers();
        },
        destroyed:function () {
            let _thisObj = this;
            _thisObj.events.forEach(function (event) {
                window.removeEventListener(event,_thisObj.resetTimers);

            });

            //this.resetTimers();
        },
        methods:{
            setTimers:function () {
                this.messageTimer = setTimeout(this.showMessage,14*60*1000);
                this.logoutTimer = setTimeout(this.logout,15*60*1000);
            },
            resetTimers:function () {
                this.showWarningMessage =  false;

                clearTimeout(this.messageTimer);
                clearTimeout(this.logoutTimer);

                this.setTimers();
            },

            logout:function(){
                this.$store.dispatch('AUTH_LOGOUT')
                    .then(() => {
                        window.console.log("REDIRECT TO LOGIN");
                        this.$clearPageHeader();
                        this.$router.push('/');
                    })
            },
            showMessage:function () {
                this.showWarningMessage =  true;
            }
        },
        computed: {

        }
    }
</script>