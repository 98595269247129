import http from "../../../http-common";

export default {
    state: {
        list_districts: null,
    },
    mutations: {
        SET_DISTRICTS_LIST: (state, _value) => {
            state.list_districts = _value;
        },
    },
    actions: {
        GET_DISTRICTS_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/districts/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_DISTRICTS_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        SET_DISTRICTS_LIST: ({commit}, _value) => {
            commit("SET_DISTRICTS_LIST", _value);

        },
    }
};