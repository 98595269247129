<template>
    <div id="myConfirmModal" class="modal">
        <div class="modal-content">
            <h4 class="modal-title">Are you sure?</h4>
        </div>
        <div class="modal-footer">


            <a
                    href="javascript:void(0);"
                    @click.prevent="closeModal"
                    class="left btn sp-gold modal-close margin-left-half margin-right-half"
            >NO</a
            >

            <a
                    href="javascript:void(0);"
                    @click.prevent="confirmAction"
                    class="right btn sp-gold  modal-close  margin-left-half margin-right-half"
            >YES</a>




        </div>
    </div>
</template>

<script>
    import "materialize-css/dist/js/materialize.min";
    import M from "materialize-css";
    import { bus } from "../../main";

    export default {
        name: "confirm-modal-component",
        props: {
            actionName: {
                type: String
            }
        },

        /**
         * @description Component local variables
         * @return {Object} data
         * @return {undefined|FormSelect} data.instance
         */
        data() {
            return {
                instance: undefined
            };
        },

        methods: {
            /**
             * @description Initialize a new Materialize select component
             * @param {Object} options
             * @return {FormSelect}
             * @see https://materializecss.com/select.html#options
             */
            initializeModal(options = {}) {
                return M.Modal.init(this.$el, options);
            },
            closeModal() {
                this.instance.close();
                bus.$emit("confirmAction", "");
            },
            confirmAction() {
                window.console.log("Confirm: "+ this.actionName);
                this.instance.close();
                bus.$emit("confirmAction", this.actionName);
            }
        },
        mounted() {
            this.instance = this.initializeModal();
        },
        watch: {
            actionName() {
                console.log("action Name Set to: " + this.actionName);
                if (this.actionName !== null && this.actionName.length > 0) {
                    this.instance.open();
                }
            }
        }
    };
</script>
