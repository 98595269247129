import axios from "axios";

export default axios.create({
  baseURL: "https://dev.brandboxbw.com/smart_procurement",
  headers: {
    /*"Content-type": "application/json"
      "http://localhost:8002/"
     https://api.brandboxbw.com/*/
  }
});
