<template>
  <div id="app">
    <loading-component></loading-component>
    <message-modal-component :messages="messages"></message-modal-component>
    <confirm-modal-component :actionName="actionName"></confirm-modal-component>
    <logout-component v-if="authenticated"></logout-component>
    <nav v-if="!isLoginPage">
      <div class="nav-wrapper" id="nav">
        <a v-if="authenticated" href="/home" class="left brand-logo">
          <img src="assets/img/logo.png"/>
        </a>

        <a href="#" data-target="mobile-demo" class="sidenav-trigger right">
          <i class="material-icons">menu</i>
        </a>
        <ul id="nav-mobile" class="right hide-on-med-and-down">
          <li><router-link to="/login" v-if="!authenticated">Login</router-link></li>
          <li><router-link to="/home" v-if="authenticated">Home</router-link></li>
          <li><router-link to="/suppliers/register" v-if="!authenticated">Register</router-link></li>
          <li><router-link to="/logs" v-if="authenticated">Logs</router-link></li>
          <li v-if="authenticated"><a to="/logout" @click.prevent="logout">Logout</a></li>
        </ul>

        <side-nav-component id="mobile-demo">
          <li><router-link to="/login" v-if="!authenticated">Login</router-link></li>
          <li><router-link to="/home" v-if="authenticated">Home</router-link></li>
          <li><router-link to="/suppliers/register" v-if="!authenticated">Register</router-link></li>
          <li><router-link to="/logs" v-if="authenticated">Logs</router-link></li>
          <li v-if="authenticated"><a  @click.prevent="logout">Logout</a></li>

        </side-nav-component>

      </div>
    </nav>

    <router-view/>
  </div>
</template>

<style>


  @import "assets/css/borders.css";
  @import "assets/css/loader.css";
  @import "assets/css/padding.css";
  @import "assets/css/margin.css";
  @import "assets/css/password-meter.css";
  @import "assets/css/style.css";
  @import "assets/css/vert-offset.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  background-color: #e6e4e4 !important;
}

#nav a {
  font-weight: bold;
  color: #a53818;
}

#nav a.router-link-exact-active {
  color: #fda100;
}
</style>

<script>
    import store from "./store/index"
    import {mapState} from "vuex"
    import LoadingComponent from "@/components/materialize/LoadingComponent.vue"
    import MessageModalComponent from "@/components/materialize/MessageModalComponent.vue"
    import SideNavComponent from "@/components/materialize/SideNavComponent.vue"
    import ConfirmModalComponent from "@/components/materialize/ConfirmModalComponent.vue";
    import LogoutComponent from "@/components/LogoutComponent.vue";
    import http from "./http-common"

    export default {
        components:{
            LogoutComponent,LoadingComponent,MessageModalComponent,SideNavComponent,ConfirmModalComponent
        },
        methods:{
            goToUrl : function(_url)
            {
                this.$router.push(_url);
            },
            logout: function () {
                console.log("We get here");

                this.$store.dispatch('AUTH_LOGOUT')
                    .then(function(){

                    console.log("We get here");
                       // this.$clearPageHeader();
                        this.$router.push('/login')
                    })
            },
            checkCurrentLogin:function ()  {
                console.log("checkCurrentLogin:");
                console.log("token "+store.getters.isAuthenticated);

                let noauthroutes = ["/","/suppliers/register"];
                if (this.$store.getters.isAuthenticated === false && !noauthroutes.includes(this.$route.path))
                {
                    window.console.log("Route: "+ this.$route.path);
                    this.$router.push('/login');
                }
            },
            checkRoute:function ()
            {
                console.log("checkRoute:");

                http.interceptors.response.use(undefined, function (err) {
                    return new Promise(function () {
                        console.log("Err: "+JSON.stringify(err));
                        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                            // if you ever get an unauthorized, logout the user
                            this.$store.dispatch('AUTH_LOGOUT')
                            // you can also redirect to /login if needed !
                        }
                        throw err;
                    });
                });
            }
        },
        created(){
            this.checkRoute();
            this.checkCurrentLogin();
        },
        updated () {
            this.checkCurrentLogin()
        },
        computed: {
            isLoginPage:function () {
                return this.$route.path === "/login" || this.$route.path === "/";
            },
            isValidMenu()
            {
                if(typeof this.menu_links === "undefined" || this.menu_links === null || this.menu_links.length <= 0)
                    return false;

                return true;
            },
            hasPageHeader()
            {
                if(
                    typeof this.page_header_html === "undefined" || this.page_header_html === null ||
                    typeof this.page_header_html.icon === "undefined" || this.page_header_html.icon === null ||
                    typeof this.page_header_html.title === "undefined" || this.page_header_html.title === null ||
                    typeof this.page_header_html.url === "undefined" || this.page_header_html.url === null
                )
                    return false;

                return true;
            },
            authenticated () {
                return this.$store.getters.isAuthenticated
            },

            ...mapState({
                messages: state => state.messages,
                actionName: state => state.actionName,
              confirm_data: state => state.confirm_data,
                organisation: state => state.organisations.organisation,
                menu_links : state => state.menu_links,
                page_header_html : state => state.page_header_html,
            })
        },
        mounted()
        {
            http.interceptors.response.use((response)=>{
                    console.log("* Response"+JSON.stringify(response));
                    if(response.data.success == false)
                    {
                        return Promise.reject(response);
                    }
                    return response
                },
                (error)=>{

                    var response = {
                        data: {
                            messages : [error.message]
                        },
                        message: error.message,
                    };

                    console.log("* Error Response"+JSON.stringify(error));

                    return Promise.reject(response);
                }
            );
        }
    }
</script>
