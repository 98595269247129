import Vue from 'vue'
import Vuex from 'vuex'
import http from "../http-common";
import districts from "./modules/districts/districts"
import organisations from "./modules/organisations/organisations"
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
      token: localStorage.getItem("token") | "",
      status: "",
      processing: 0,
      messages: [],
      actionName: "",
      user: null,
      timeout: 30000,
      menu_links: [],
      page_header_html: null,
      confirm_data:"",
      list_cities: null,
      list_districts: null,

  },
    getters: {
        isAuthenticated: state => !!state.token,
        authStatus: state => state.status,
        isProcessing: state => state.processing,
       /* system-admin
        super-admin
        auditor
        organisation-admin
        business-admin
        standard-user
        business-user*/
        isSystemAdmin: state => {
            let roles = [];
            for (let i = 0; i < state.user.roles.data.length; i++) {
                roles.push(state.user.roles.data[i].type.data.alias);
            }
            console.log("Roles: " + JSON.stringify(roles));
            return roles.includes("system-admin") || roles.includes("super-admin");

        },
        isAdmin: state => {
            let roles = [];
            for (let i = 0; i < state.user.roles.data.length; i++) {
                roles.push(state.user.roles.data[i].type.data.alias);
            }
            console.log("Roles: " + JSON.stringify(roles));
            if (roles.includes("organisation-admin") || roles.includes("auditor")) {
                return true;
            }
            return false;
        },
        isBusinessUser: state => {
            let roles = [];
            for (let i = 0; i < state.user.roles.data.length; i++) {
                roles.push(state.user.roles.data[i].type.data.alias);
            }
            console.log("Roles: " + JSON.stringify(roles));
            if (roles.includes("business-admin") || roles.includes("business-user")) {
                return true;
            }
            return false;
        },
        isStandard: state => {
            let roles = [];
            for (let i = 0; i < state.user.roles.data.length; i++) {
                roles.push(state.user.roles.data[i].type.data.alias);
            }
            console.log("Roles: " + JSON.stringify(roles));
            if (roles.includes("standard-user")) {
                return true;
            }
            return false;
        }
    },
    mutations: {
        SET_DISTRICTS_LIST: (state, _value) => {
            state.list_districts = _value;
        },
      AUTH_REQUEST: state => {
          state.status = "loading";
      },
      AUTH_SUCCESS: (state, token) => {
          state.status = "success";
          state.token = token;
          http.defaults.headers.common["Authorization"] = "Bearer " + token;
      },
      AUTH_ERROR: state => {
          state.status = "error";
      },
      AUTH_LOGOUT: state => {

          console.log("AUTH_LOGOUT Commit");

          state.status = "";
          state.token = null;
          state.processing = 0;
          state.messages = [];
          state.actionName = "";
          state.confirm_data = "";
          state.user = null;
          state.list_cities = null;
          localStorage.removeItem("token");
          delete http.defaults.headers.common["Authorization"];
      },
      START_PROCESSING: state => {
          state.processing++;
          window.console.log("Processing - Start: " + state.processing);
      },
      STOP_PROCESSING: state => {
          if (state.processing > 0) {
              state.processing--;
          }
          window.console.log("Processing - Stop: " + state.processing);
      },
      SET_MESSAGES: (state, _messages) => {
          window.console.log(JSON.stringify(_messages));
          state.messages = _messages;
      },
      SET_ACTION: (state, _value) => {
          window.console.log("State Mutation: " + JSON.stringify(_value));
          state.actionName = _value;
      },
      USER: (state, _value) => {
          state.user = _value;
      },
        SET_CITIES_LIST: (state, _value) => {
            console.log("Mutations Cities: "+_value);

            state.list_cities = _value;
        },

  },
    actions: {
        GET_DISTRICTS_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/districts/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_DISTRICTS_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        SET_DISTRICTS_LIST: ({commit}, _value) => {
            commit("SET_DISTRICTS_LIST", _value);

        },
      START_PROCESSING: ({ commit }) => {
          commit("START_PROCESSING");
      },
      STOP_PROCESSING: ({ commit }) => {
          commit("STOP_PROCESSING");
      },
      SET_ACTION: ({ commit }, _value) => {
          commit("SET_ACTION", _value);
      },
      AUTH_REQUEST: ({ commit ,dispatch}, user) => {
          return new Promise(resolve => {
              // The Promise used for router redirect in login
              commit("AUTH_REQUEST");
              commit("START_PROCESSING");
              http({ url: "/login", data: user, method: "POST", timeout: 30000 })
                  .then(resp => {
                      commit("STOP_PROCESSING");

                      if (
                          typeof resp.data !== "undefined" &&
                          resp.data !== null
                      ) {

                          if (typeof resp.data.data !== "undefined" && resp.data.data !== null)
                          {

                              window.console.log("Response:" + JSON.stringify(resp));
                              window.console.log("user:" + JSON.stringify(resp.data.data.user));
                              const token = resp.data.data.token;
                              localStorage.setItem("token", token); // store the token in localstorage

                              http.defaults.headers.common["Authorization"] = "Bearer " + token;
                              commit("USER", resp.data.data);

                              commit("AUTH_SUCCESS", token);
                          }
                          else {
                              commit("SET_MESSAGES",resp.data.messages, { root: true });
                          }

                      }

                      // you have your token, now log in your user :)
                      //dispatch('USER_REQUEST');
                      resolve(resp);
                  })
                  .catch(resp => {
                      window.console.log("Error Response: " + JSON.stringify(resp));
                      commit("STOP_PROCESSING");
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", resp);


                  });
          });
      },
      AUTH_LOGOUT: ({ commit }) => {
          return new Promise(resolve => {

              console.log("AUTH_LOGOUT");
              commit("AUTH_LOGOUT");
              resolve();
          });
      },
      RESPONSE_PROCESS_AJAX_ERROR: ({ commit }, _value) => {
          let _arrMsg = [];
          if (typeof _value.data !== "undefined" && _value.data !== null) {
              _arrMsg = _value.data.messages;
          } else if (
              typeof _value.message !== "undefined" &&
              _value.message !== null
          ) {
              _arrMsg.push(_value.message);
          } else {
              window.console.log("Unhandled-Error------");
              window.console.log(JSON.stringify(_value));
              _arrMsg.push(
                  "Unhandled Error - An error occurred, please try again later."
              );
          }
          commit("SET_MESSAGES", _arrMsg);
      },

      GET_MESSAGES: ({ commit }, messages) => {
          console.log("SET MESSAGES TO " + JSON.stringify(messages));
          commit("SET_MESSAGES", messages);
      },
        GET_CITIES_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/cities/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                console.log("Cities: "+_response.data.data);
                                commit("SET_CITIES_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        SET_CITIES_LIST_ACTION: ({commit}, _value) => {
            commit("SET_CITIES_LIST", _value);

        },

  },
  modules: {
      districts,organisations
  },
    plugins: [
        createPersistedState({
            key: "stored_state",
            reducer(state) {
                // window.console.log("IN reducer: "+JSON.stringify(val));

                let reduced_state = {};
                if (state.token !== null || state.token !== 0) {
                    // val.user.token (your user token for example)

                    reduced_state = JSON.parse(JSON.stringify(state));
                    delete reduced_state["processing"];
                    delete reduced_state["messages"];
                    // window.console.log("IN reducer: " + JSON.stringify(reduced_state));

                    return reduced_state;
                }
                // window.console.log("OUT reducer: "+JSON.stringify(reduced_state));

                return reduced_state;
            }
        })
    ]
})
