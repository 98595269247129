import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";

Vue.use(VueRouter);
const isAuthenticated = (to, from, next) => {

    window.console.log("Authenticated: " + store.getters.isAuthenticated);
    window.console.log("Authenticated: " + to.value);
    if (!store.getters.isAuthenticated) {
        return next("/login");
    } else {
        next();
    }
};
const routes = [
    {
        path: '/',
        name: 'SignIn',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/suppliers/register',
        name: 'RegisterSupplier',
        component: () => import(/* webpackChunkName: "about" */ '../views/suppliers/Register.vue')
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/suppliers/Home.vue'),
        beforeEnter:isAuthenticated
    },
    {
        path: '/login',
        name: 'SignIn',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
    {
        path: '/logs',
        name: 'Logs',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/activity_logs/Home.vue')
    },
    {
        path: '/suppliers/view',
        name: 'SupplierView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/suppliers/View.vue'),
        beforeEnter:isAuthenticated
    },

];

const router = new VueRouter({
  routes
});

export default router
