import http from "../../../http-common";

export default {
    state: {
        list_businesstypes:null,
        list_patronagetypes:null,
        list_ownershiptypes:null,
        list_producttypes:null,
        list_industries:null,
        list_sectors:null,
        list_documenttypes:null,
        list_metrics:null,
        supplier:null,
        list_suppliers:null,
        paginated_list_suppliers:null,
        list_countries: null,
        list_products: null,
    },
    mutations: {
        SET_COUNTRIES_LIST: (state, _value) => {
            state.list_countries = _value;
        },
        SET_PRODUCTS_LIST: (state, _value) => {
            state.list_products = _value;
        },
        SET_BUSINESSTYPES_LIST: (state, _value) => {
            state.list_businesstypes = _value;
        },
        SET_PATRONAGETYPES_LIST: (state, _value) => {
            state.list_patronagetypes = _value;
        },
        SET_OWNERSHIPTYPES_LIST: (state, _value) => {
            state.list_ownershiptypes = _value;
        },
        SET_PRODUCTTYPES_LIST: (state, _value) => {
            state.list_producttypes = _value;
        },
        SET_DOCUMENTTYPES_LIST: (state, _value) => {
            state.list_documenttypes = _value;
        },
        SET_METRICS_LIST: (state, _value) => {
            state.list_metrics = _value;
        },
        SET_INDUSTRIES_LIST: (state, _value) => {
            state.list_industries = _value;
        },
        SET_SECTORS_LIST: (state, _value) => {
            state.list_sectors = _value;
        },
        SET_SUPPLIER: (state, _value) => {
            state.supplier = _value;
        },

        FC_ADD_PAGINATED_LIST_SUPPLIERS(state, _value) {
            if (
                typeof state.paginated_list_suppliers !== "undefined" &&
                state.paginated_list_suppliers !== null
            ) {
                if (
                    typeof _value !== "undefined" &&
                    _value !== null &&
                    _value.length > 0
                ) {
                    for (let i = 0; i < _value.length; i++)
                        state.paginated_list_suppliers.push(_value[i]);
                } else {
                    state.paginated_list_suppliers = null;
                }
            } else {
                state.paginated_list_suppliers = _value;
            }
        },
        FC_SET_PAGINATED_LIST_SUPPLIERS(state, _value) {
            state.paginated_list_suppliers = _value;
        },
        SET_SUPPLIERS_LIST: (state, _value) => {
            state.list_suppliers = _value;
        },
    },
    actions: {
        GET_COUNTRIES_LIST: ({ commit, dispatch }) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/countries/list',
                    method: "POST",
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_COUNTRIES_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING",{root:true});
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        GET_BUSINESSTYPES_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/types/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_BUSINESSTYPES_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        GET_PATRONAGETYPES_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/entities/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_PATRONAGETYPES_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        GET_OWNERSHIPTYPES_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/ownerships/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_OWNERSHIPTYPES_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        GET_PRODUCTTYPES_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/products/types/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_PRODUCTTYPES_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        GET_PRODUCTS_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/products/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_PRODUCTS_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        GET_DOCUMENTTYPES_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/documents/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_DOCUMENTTYPES_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        GET_METRICS_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/metrics/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_METRICS_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        GET_INDUSTRIES_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/industries/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_INDUSTRIES_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        GET_SECTORS_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/sectors/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                               commit("SET_SECTORS_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        GET_SUPPLIER: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/view',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                               commit("SET_SUPPLIER", _response.data.data);
                            } else
                                {

                                commit("SET_MESSAGES",_response.data.messages, { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        FC_ADD_PAGINATED_LIST_SUPPLIERS: ({ commit }, _value) => {
            commit("FC_ADD_PAGINATED_LIST_SUPPLIERS", _value);
        },
        FC_SET_PAGINATED_LIST_SUPPLIERS: ({ commit }, _value) => {
            commit("FC_SET_PAGINATED_LIST_SUPPLIERS", _value);
        },
        GET_SUPPLIERS: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: "/suppliers",
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                if (
                                    typeof _formData !== "undefined" &&
                                    _formData !== null &&
                                    typeof _formData.page !== "undefined" &&
                                    _formData.page !== null &&
                                    _formData.page > 1
                                ) {
                                    console.log("Paginated List Suppliers: "+_response.data);
                                    commit("FC_ADD_PAGINATED_LIST_SUPPLIERS", _response.data.data);
                                } else {
                                    console.log("Paginated List Suppliers: "+JSON.stringify(_response.data));

                                    commit("FC_SET_PAGINATED_LIST_SUPPLIERS", _response.data.data);
                                }
                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ]);
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        GET_SUPPLIERS_LIST: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/list',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                               commit("SET_SUPPLIERS_LIST", _response.data.data);
                            } else {
                                commit("SET_MESSAGES",_response.data.messages, { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        SUPPLIERS_SAVE: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/save',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                               commit("SET_MESSAGES", ["Registration Successful, Please check your email to verify your account."],{root:true});
                            } else {
                                commit("SET_MESSAGES",_response.data.messages, { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        SAVE_PATRONAGETYPE: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/update/entity',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                }).then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_SUPPLIER", _response.data.data);
                                commit("SET_MESSAGES", ["Update Successful"],{root:true});

                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        SAVE_OWNERSHIPTYPE: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/update/ownership',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                }).then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_SUPPLIER", _response.data.data);
                                commit("SET_MESSAGES", ["Update Successful"],{root:true});


                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        SAVE_INDUSTRY: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/update/industries',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                }).then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_SUPPLIER", _response.data.data);
                                commit("SET_MESSAGES", ["Update Successful."]);

                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING",{root:true});
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        SAVE_SECTOR: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/update/sectors',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                }).then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_SUPPLIER", _response.data.data);
                                commit("SET_MESSAGES", ["Update Successful."]);

                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        SAVE_PRODUCT: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/update/products',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                }).then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_SUPPLIER", _response.data.data);
                                commit("SET_MESSAGES", ["Update Successful."]);

                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        SAVE_CONTACTS: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/update/contacts',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                }).then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_SUPPLIER", _response.data.data);
                                commit("SET_MESSAGES", ["Update Successful"],{root:true});


                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        SAVE_LOCALITY: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/update/locality',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                }).then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_SUPPLIER", _response.data.data);
                                commit("SET_MESSAGES", ["Update Successful"],{root:true});

                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        SAVE_LOGO: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/update/logo',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                }).then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_SUPPLIER", _response.data.data);
                                commit("SET_MESSAGES", ["Update Successful"],{root:true});


                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        SAVE_DOCUMENT: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/update/document',
                    method: "POST",
                    data:_formdata,
                    header:{
                        "Content-Type":"multipart/form-data"
                    },
                    timeout: 30000
                }).then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        if (
                            typeof _response.data !== "undefined" &&
                            _response.data !== null
                        ) {
                            if (
                                typeof _response.data.data !== "undefined" &&
                                _response.data.data !== null
                            ) {
                                commit("SET_SUPPLIER", _response.data.data);
                                commit("SET_MESSAGES", ["Update Successful"],{root:true});

                            } else {
                                commit("SET_MESSAGES", [
                                    "An error occurred, please try again later"
                                ], { root: true });
                            }
                        }

                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        SAVE_METRIC: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/update/metrics/add',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                }).then(_response => {
                    commit("STOP_PROCESSING", { root: true });
                    if (
                        typeof _response.data !== "undefined" &&
                        _response.data !== null
                    ) {
                        if (
                            typeof _response.data.data !== "undefined" &&
                            _response.data.data !== null
                        ) {
                            commit("SET_SUPPLIER", _response.data.data);
                            commit("SET_MESSAGES", ["Update Successful"],{root:true});


                        } else {
                            commit("SET_MESSAGES", [
                                "An error occurred, please try again later"
                            ], { root: true });
                        }
                    }

                    resolve(_response);
                })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        SUPPLIER_VERIFY_DOCUMENT: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/verify/document',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                }).then(_response => {
                    commit("STOP_PROCESSING", { root: true });
                    if (
                        typeof _response.data !== "undefined" &&
                        _response.data !== null
                    ) {
                        if (
                            typeof _response.data.data !== "undefined" &&
                            _response.data.data !== null
                        ) {
                            commit("SET_SUPPLIER", _response.data.data);
                            commit("SET_MESSAGES", ["Verification Successful"],{root:true});


                        } else {
                            commit("SET_MESSAGES", [
                                "An error occurred, please try again later"
                            ], { root: true });
                        }
                    }

                    resolve(_response);
                })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        SUPPLIER_VERIFY_METRIC: ({ commit, dispatch },_formdata) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/suppliers/verify/metric',
                    method: "POST",
                    data:_formdata,
                    timeout: 30000
                }).then(_response => {
                    commit("STOP_PROCESSING", { root: true });
                    if (
                        typeof _response.data !== "undefined" &&
                        _response.data !== null
                    ) {
                        if (
                            typeof _response.data.data !== "undefined" &&
                            _response.data.data !== null
                        ) {
                            commit("SET_SUPPLIER", _response.data.data);
                            commit("SET_MESSAGES", ["Verification Successful"],{root:true});


                        } else {
                            commit("SET_MESSAGES", [
                                "An error occurred, please try again later"
                            ], { root: true });
                        }
                    }

                    resolve(_response);
                })
                    .catch(_response => {
                        commit("STOP_PROCESSING");
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },


    }
};